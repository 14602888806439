.group {
  width: 100%;
  &:last-child {
    margin-bottom: 20px
  }
}

.uorBtn {
  border: none;
  color: #fff;
  text-align: left;
  padding: 12px 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}

%arrow {
  font-size: 10px;
  line-height: 10px;
  transition: transform .2s ease;
}

.arrowUp {
  @extend %arrow;
  transform: rotate(0);
}

.arrowDown {
  @extend %arrow;
  transform: rotate(180deg);
}

.collapse {
  width: 100%;
  background: rgba(97, 104, 127, 0.2);
  padding: 0;
}
