.container {
  //width: 150px !important;
}

.group {
  // margin: 0 8px;
}

.button {
  line-height: 14px;
  font-size: 14px;
  background: rgba(221, 231, 237, 0.61);
  border-color: rgb(215, 222, 228);
  color: #8c99a5;
}