$cardHeight: 85%;

.siteContainer {
  width: 600px;
  height: 600px;
  width: calc(100% + 30px);
  height: calc(100% - 76px);
  overflow: hidden;
}

.map {
  position: relative;
  width: 100%;
  height: 100%;
}

.card {
  position: absolute;
  top: 100%;
  height: $cardHeight;
  overflow-y: hidden;
  z-index: 1000;
  background: #eef2f4;
  margin: 0;
  min-width: 100%;
  transform: translateY(100%);
  opacity: 0;
  //transition: opacity .3s linear;
  padding: 0;
  z-index: 0;
}

%site {
  position: relative;
  width: 100%;
  overflow-y: visible;
  height: 100%;
}

.siteMap {
  @extend %site;
}

.siteCard {
  @extend %site;
  .map {
    filter: brightness(80%);
  }
  .card {
    opacity: 1;
    transform: translateY(-100%);
  }
}

.cardHeader {
  margin: 0;
  padding: 30px;
  height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #61747f;
}

.cardTitle {
  font-size: 36px;
  font-weight: bold;
}

.closeBtn {
  border: 0;
  color: #61747f;
  font-size: 23px;
  line-height: 23px;
}

.cardContent {
  height: calc(100% - 94px);
  overflow-y: auto;
}