.content {
  color: #61687f;
  font-size: 16px;
  padding: 20px;
  word-break: break-all;
  hyphens: auto;
  padding-bottom: 55px;
  &>div {
    margin-bottom: 6px;
  }
}

.footer {
  color: #61687f;
  position: absolute;
  bottom: 0;
  height: 45px;
  line-height: 45px;
  width: 100%;
  border-top: 1px solid #eceff1;
  padding: 0 20px;
  font-size: 14px;
}

.state {
  padding-right: 12px;
  border-right: 1px solid #eceff1;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: baseline;
    margin-right: 8px;
  }
}

.type {
  padding-left: 12px;
}

.btnEdit {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  width: 40px;
  height: 40px;

  font-size: 14px;
  color: #8c99a5;
  border: 0;
  background-color: #fff !important;
  border-radius: 50%;

  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.content:hover {
  .btnEdit {
    opacity: 1;
  }
}

.energy {
  &:before {
    font-family: 'eleneo';
  }

  &.gas:before {
    content: "q ";
    color: #c53ed1;
  }

  &.elec:before {
    content: "o ";
    color: #31acea;
    position: relative;
    top: 3px;
  }

  &.water:before {
    content: "! ";
    color: #00d4c3;
    position: relative;
    top: 3px;
  }
}

.alert {
  font-size: 18px;
  font-weight: bold;
}

.alertModel {
  font-size: 12px;
}

.gain {
  font-weight: bold;
}

.gainValue {
  color: #00c793;
}

.cpt{
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
}
