.header1 {
  position: sticky;
  top: 76px;
  z-index: 20;
  height: 50px;
  background: #fff;
}

.header2 {
  position: sticky;
  top: 76px;
  z-index: 10;
  background: #fff;
  margin-bottom: 30px;
}

.periodSelector {
  height: 100px;
}