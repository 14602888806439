.patrimonials {
  padding: 20px 0;

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  select, input, button {
    font-size: 0.9rem;
  }
}
