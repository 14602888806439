$font-family-sans-serif: Muli, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-xs: 0.75rem;
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;

$grid-gutter-width-base: 30px !default;

$gold: #926A14 !default;
$blue: #21215A !default;
$cyan: #515BB3 !default;
$red: #B9525A !default;
$orange: #ED7250 !default;
$yellow: #F3984B !default;
$purple: #645892 !default;
$green: #51b87c !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$black: #000000;
$white: #ffffff;
$white-transparent: rgba(255, 255, 255, 0.75);

$alt-white: $gray-100;
$alt-white-dark: $gray-200;
$gray-lighter: $gray-300;
$gray-light: $gray-500;
$gray: $gray-600;
$gray-dark-lighten: $gray-700;
$gray-darken: $gray-800;
$gray-dark: $gray-900;

$green-light: lighten($green, 20);
$green-dark: darken($green, 20);
$green-darken: darken($green, 40);

$gold-light: lighten($gold, 10);
$gold-dark: darken($gold, 10);
$gold-darken: darken($gold, 20);

$blue-light: lighten($blue, 10);
$blue-dark: darken($blue, 10);
$blue-darken: darken($blue, 20);

$blue-darken: #1aa3e8;
$purple-darken: #ba2fc6;

$primary: $cyan !default;
$brand-primary: $blue;
$secondary: $gold !default;
$success: $gold !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$dark: $gray-800 !default;

$border-radius: .123rem !default;
$border-radius-lg: .15rem !default;
$border-radius-sm: .1rem !default;

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-rfs: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

$arrow-up: $red;
$arrow-down: $green;
$arrow-right: $cyan;

$sidebar-width: 250px;
$seachPanel-width: 480px;

$elec: #31acea;
$gas: #c53ed1;

:root {
  --gold: #926A14;
  --gold-dark: #65490e;
  --top: #00ba8a;
  --top-light: #b2eede;
  --flop: rgb(185, 82, 90);
  --flop-light: rgba(185, 82, 90, 0.36);
  --blue-alpha3: rgba(33, 33, 90, 0.3);
  --blue-alpha7: rgba(33, 33, 90, 0.7);
}
