.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &>* {
    margin: 0 !important;
  }

  &>*:not(:first-child) {
    margin-left: 20px !important;
  }
}

.container {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 12px;
  color: #61687f;
}

%optionIcon {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.optionIconElec {
  @extend %optionIcon;
  color: #31acea;
}

.optionIconGaz {
  @extend %optionIcon;
  color: #c53ed1;
}

.optionIconWater {
  @extend %optionIcon;
  color: #00d4c3;
}