.main-col {
  .main-header {
    position: sticky;
    top: 0px;
    z-index: 400;

    &.not-sticky {
      position: static;
    }

    // + *
    //   margin-top: 45px

    header {
      padding: 15px 40px 0;
      box-shadow: 0 -5px 5px 5px $gray-lighter;
      box-shadow: 0 -5px 5px 5px var(--gray-lighter, $gray-lighter);

      h1 {
        font-size: 14px;
        font-weight: 500;
        color: $gray-light;
        margin-bottom: 0;
      }

      .nav {
        .nav-item {
          position: relative;

          &:before {
            content: "S";
            font-family: "eleneo";
            color: $gray-lighter;
            position: absolute;
            top: 50%;
            left: -40px;
            font-size: 20px;
            transform: translate(0, -50%);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &:first-child {
            &:before {
              display: none;
            }
          }

          a {
            color: $gray-light;
            font-size: 16px;
            padding: 11px 60px 11px 0;
            display: inline-block;

            .badge {
              width: 25px;
              height: 25px;
              border-radius: 100%;
              padding: 0;
              font-size: 16px;
              font-weight: 500;
              background: none;
              border: 1px solid $gray-light;
              color: $gray-light;
              line-height: 1.3;
              vertical-align: top;
              position: relative;
              margin-right: 4px;
            }

            &.active {
              color: $primary;

              .badge {
                color: $white;
                background: $primary;
                border-color: $primary;
              }
            }
          }
        }
      }

      .menu-sticky {
        display: none;
        position: relative;
        top: 2px;

        &.affix {
          display: block;
        }

        .btn {
          border-radius: 0;
          border: none;
          color: $primary;
          text-decoration: none;
          cursor: pointer;
          text-transform: uppercase;

          i {
            position: relative;
            top: -2px;
            margin-right: 5px;
            font-size: 18px;

            &.icon-smallarrow-down {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
