.label {
  color: #2e323d;
  font-weight: bold;
  font-size: 14px !important;
}

.datepicker {
  input {
    width: 100px !important;
    margin-left: 16px;
  }
}

.regime {
  margin: .4rem;
}