.table {
  tr {
    display: block;
    width: 100%;
  }

  thead {
    font-size: 13px;
    color: #8c99a5;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 2px solid #d7dee4;
    display: block;

    th {
      padding-top: 0;
      display: inline-block;
      width: 18%;
    }

    th:first-child {
      width: 34%;
    }

    th:last-child {
      width: 12%;
    }
  }

  tbody {
    font-size: 13px;
    color: #2e323d;
    height: 176px;
    overflow-y: auto;
    display: block !important;

    tr:nth-of-type(odd) {
      background-color: #f3f7f9 !important;
    }

    td {
      display: inline-block;
      width: 18%;
      height: 44px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    td:first-child {
      width: 34%;
    }

    td:last-child {
      width: 12%;
    }
  }
}
