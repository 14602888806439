/// Mixin to customize scrollbars (Google Chrome only)
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [tranparent] - Scrollbar's color
/// @example @include scrollbars(.5em, #708090);
@mixin scrollbars($size, $foreground-color, $background-color: transparent) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-track {
    background: background-color;
	  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }
}