.highcharts-root {
  font-family: $font-family-sans-serif !important;
}

.highcharts-legend-item {
  text {
    fill: $gray !important;
  }
}

path.highcharts-legend-nav-active {
	fill: $cyan;
}

path.highcharts-legend-nav-inactive {
	fill: $gray;
}

.highcharts-axis-labels {
  text {
    fill: $gray !important;
  }
}

.highcharts-tooltip {
  text {
    fill: $gray !important;
  }
}

.highcharts-credits {
  display: none;
}

.highcharts-legend-item {
  .highcharts-grid-line {
    display: none !important;
  }
}
