
.bar {
  display: flex;
  align-items: flex-start;
  margin-bottom: 26px;
}

.controls {
  display: flex;
  align-items: flex-start;
}

.sortsFilters {
  display: flex;
  flex-direction: column;
  color: #61687f;
  font-size: 16px;
  margin-left: 30px;
}

.button {
  white-space: nowrap;
  font-size: 14px;
  color: #61687f;
  border-color: #d7dee4;
  background-color: #fff !important;
  display: flex;
  align-items: center;

  &.active {
    font-weight: bold !important;
    background: #d7dee4 !important;
  }
  &:hover,
  &:focus {
    color: #61687f !important;
    border-color: #d7dee4 !important;
  }
}

.icon {
  height: 16px;
  fill: #61687f;
  margin-right: 8px;
}

.sorts {
  display: flex;
  align-items: center;
  .button {
    &:after {
      display: inline-block;
      margin-left: .5em;
      vertical-align: .255em;
      content: "";
      border-right: .3em solid transparent;
      border-left: .3em solid transparent;
      border-top: .3em solid;
      border-bottom: 0;
      opacity: 0.3;
    }

    &.down:after {
      border-top: .3em solid;
      border-bottom: 0;
      opacity: 1;
    }

    &.up:after {
      border-top: 0;
      border-bottom: .3em solid;
      opacity: 1;
    }
  }
}

.label {
  margin-right: 10px;
  &:after {
    content: " : "
  }
}

.filters {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.addBtn {
  white-space: nowrap;
}