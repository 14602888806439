.container {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 12px;
  color: #61687f;
}

.period {
  display: flex;
}

.period > *:not(:last-child) {
  margin-right: 2px;
}

%optionIcon {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.optionIconElec {
  @extend %optionIcon;
  color: #31acea;
}

.optionIconGaz {
  @extend %optionIcon;
  color: #c53ed1;
}

.optionIconWater {
  @extend %optionIcon;
  color: #00d4c3;
}
