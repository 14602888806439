.form {
  margin-top: 25px;
  display: flex;
  align-items: center;
  h3 {
    margin: 0;
  }
  label {
    color: rgb(97, 116, 127);
    margin-right: 8px;
    font-weight: bold;
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }
}

.btnSubmit {
  height: 36px;
  margin-left: 20px;
  font-size: 14px;
  padding: 4px 12px;
  font-weight: 600;
}

.datepicker {
  input {
    font-size: 14px !important;
    height: 36px !important;
    width: 100px !important;
  }
  button {
    height: 36px !important;
  }
}

.uploadForm {
  display: flex;
}

.uploadButton {
  cursor: pointer;
  height: 36px;
  font-size: 14px;
  line-height: 28px;
  padding: 4px 12px;
  font-weight: 600;
  margin-right: 20px;
}

.uploadIcon {
  fill: #fff;
  width: 18px;
  height: 18px;
  margin: 5px 0;
}

.downloadIcon {
  fill: #fff;
  width: 18px;
  height: 18px;
  margin: 5px 0 5px 12px;
}

.uploadLoader {
  width: 18px;
  height: 18px;
}

.uploadLabel {
  margin-right: 12px;
}

.alert {
  margin-bottom: 0;
  line-height: 28px;
  padding: 4px 4rem 4px 12px;
  button {
    padding: 4px 12px !important;
  }
}