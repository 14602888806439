.form-group {
  margin-bottom: 0;
  align-items: center;
}

.col-cp {
  flex: 0 0 60px;
  max-width: 60px;

  input {
    padding-right: 0;
    width: 50px !important;
  }
}

.input {
  color: #495057;
  border-radius: 0;
  padding: 0.175rem 0.35rem;
  font-size: 0.9em;
}

.section-custom {
  padding: 30px;
  background: #fff;
  box-shadow: 0px 0px 5px $gray-lighter;
  box-shadow: 0px 0px 5px var(--gray-lighter, $gray-lighter);
  margin-bottom: 30px;

  .section-custom & {
    padding: 20px;
    border: 1px solid #eee;
    box-shadow: none;
  }

  .tab-pane & {
    margin: 0;
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 20px;

    &--special {
      position: relative;
      line-height: 0;
      top: -28px;
      background: #fff;
      display: inline-block;
      padding: 8px;
      margin: 0;
    }
  }
}

.textarea {
  border: 1px solid #DDD;
  padding: 2px;
  margin-top: 4px;
  height: 100px;
  overflow: auto;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;

  //border-radius: 0.25rem

  .row {
    margin: 0;
  }

  .col {
    padding: 2px 0;
  }

  input {
    border: 0;
    color: #495057;
    width: 100%;
  }
}

.tags {
  margin: 10px 0;

  .badge {
    background: $blue;
    margin: 0 5px 5px 0;
    font-size: 100%;
    color: #fff;
    padding: 0.7em;
  }

  .delete {
    margin: 0 5px;
    cursor: pointer;
    font-weight: normal;
  }
}

.compteurs-nav {
  margin-top: 30px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .nav-item {
    flex: 0 0 auto;
  }

  .nav-link {
    cursor: pointer;
    font-size: 0.9em;
    display: inline-block;
    border-radius: 2px 2px 0 0;
    background: #ddd;
    margin: 0 5px 0 0;

    &.active {
      position: relative;
      background-color: #fff;

      //color: #fff
      &:hover {
        //color: #fff

      }
    }
  }
}
