$focusColor: transparentize($gray-light, 0.3);

.react-select {
  .react-select__control {
    cursor: default;
  }

  .react-select__control--is-focused {
    border-color: transparent;
    box-shadow: 0 0 0 1px $gray-light;
    &:hover {
      border-color: transparent;
    }
  }

  .react-select__option {
    cursor: default;
    &:active {
      background: var(--primary);
      color: #fff;
    }
  }

  .react-select__option--is-focused {
    background: $focusColor;
  }

  .react-select__option--is-selected {
    background: var(--primary);
  }
}

.date-range {
  .react-select {
    height: 38px;
  }

  .react-select__control {
    border: none;
    box-shadow: none;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__menu {
    border-radius: 2px;
    margin-top: 0;
  }
}

.react-select--small {
  .react-select__control {
    border-radius: 3px;
  }

  .react-select__menu {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-top: -2px;
  }
}

.react-select__group-heading {
  background-color: rgb(215, 222, 228);
  color: #61687f !important;
  font-weight: bold !important;
  padding: 5px 0;
}
