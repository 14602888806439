.popup {
  width: 256px;
}

.card {
  border: 0;
}

.title {
  color: #61747f;
  font-weight: bold;
  font-size: 17px;
}

.text {
  color: #2e323d;
  font-size: 14px;
}

.buttons {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  >* {
    width: auto;
    margin-top: 10px;
  }
}

.button {
  padding: 0 18px 0 16px !important;
  height: 36px !important;
}

.ico {
  margin-right: 12px;
  top: -2px;
  position: relative;
}
