%card {
  width: 100%;
  border: 1px solid #d7dee4;
  color: #2e323d;
  margin: 0;
}

.topCard {
  @extend %card;

  .header {
    background-color: var(--top-light);
  }

  .gap {
    color: var(--top);
    i {
      color: var(--top-light);
      -webkit-text-stroke-color: var(--top);
    }
  }
}

.flopCard {
  @extend %card;

  .header {
    background-color: var(--flop-light);
  }

  .gap {
    color: var(--flop);
    i {
      color: var(--flop-light);
      -webkit-text-stroke-color: var(--flop);
    }
  }
}

.header {
  padding: 10px 16px;
  font-size: 14px;
}

.label {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: #8c99a5;
  margin-right: 5px
}

.value {
  font-size: 13px;
  font-weight: bold;
  color: #2e323d;
}

.gap {
  font-size: 25px;
  font-weight: bold;
  -webkit-text-stroke-width: 1px;
  i {
    vertical-align: text-bottom;
    margin-left: 5px;
  }
}
