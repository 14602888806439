.topNav {
  padding: 0 10px 0 5px;
  width: 100%;
  z-index: 500;

  .logo {
    padding: 22px 55px 28px;
    display: block;

    .img-logo {
      height: 40px;
    }
  }

  .leftNav {
    align-items: flex-end;
    .nav-item {
      white-space: nowrap;
    }
    .nav-link i {
      margin-right: 8px;
    }
  }

  .rightNav {
    height: 100%;
    align-items: flex-end;
    .nav-item {
      white-space: nowrap;
    }
    .nav-link i {
      margin-right: 8px;
    }
  }

  .userNavItem {
    height: 76px;
    button {
      height: 100%;
      padding-bottom: 10px;
    }
  }

  .nav-link {
    font-size: $font-size-base;
    padding: 0 16px !important;
    font-weight: normal;
    color: $white-transparent;
    color: var(--white-transparent, $white-transparent);

    &:hover {
      color: $white !important;
      color: var(--white, $white) !important;
    }

    span {
      display: block;
      padding: 26px 0 !important;
      position: relative;

      .badge {
        width: 1.5em;
        height: 1.5em;
        line-height: 1.4em;
        display: inline-block;
        vertical-align: middle;
        padding: 0 !important;
        margin-left: 0.5em;
        border-radius: 50%;
        background: $red;
        background: var(--red, $red);
        position: relative;
        top: -1px;
      }
    }

    &.active {
      color: $white !important;

      span {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 7px;
          background: $gold-dark;
        }
      }
    }
  }

  .btn-link {
    color: $white;
    color: var(--white, $white);
    font-size: $font-size-lg;
    text-decoration: none;
    cursor: pointer;

    .icon-smallarrow-down {
      font-size: $font-size-xs;
      margin-left: 6px;
    }
  }
}
