.date-range {
  margin: 20px 0px;
  position: relative;
  z-index: 1;

  input {
    height: auto;
  }

  .date-range-wrapper {
    display: flex;
    perspective: 1000px;
    position: relative;

    .date-range-global {
      display: flex;
    }

    .date-range-load-curve {
      display: flex;
    }
  }

  .form-group {
    border: 1px solid $gray-lighter;
    border: 1px solid var(--gray-lighter, $gray-lighter);
    padding: 10px 20px;
    display: block;
    position: relative;
    will-change: transform;
    backface-visibility: hidden;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &:nth-child(3) {
      border-left: none;

      &:after {
        content: "a";
        font-family: eleneo;
        position: absolute;
        bottom: 6px;
        left: -22px;
        color: $gray-light;
        color: var(--gray-light, $gray-light);
        font-size: 1.6rem;
      }
    }

    &:not(:last-child) {
      border-right: none;
    }

    label {
      color: $gray-light;
      color: var(--gray-light, $gray-light);
      display: block;
      font-size: $font-size-xs;

      i {
        position: absolute;
        top: 30px;
        right: 20px;
        pointer-events: none;

        &.icon-event {
          right: auto;
          left: 16px;
          height: 38px;
          line-height: 38px;
          margin-top: 1px;
        }
      }
    }

    input {
      border: none;
      padding: 0;
      appearance: none;
      cursor: pointer;
      width: 150px;
      height: auto !important;
      margin: 0;
      border-radius: 0;
      background: none;

      &:focus {
        box-shadow: none;
      }
    }

    input {
      padding-left: 18px !important;
    }

    input[type="checkbox"] {
      width: auto;
    }

    input[type="text"] {
      width: 120px;
    }

    .rdt {
      width: auto !important;
      height: 38px;
      line-height: 38px;
    }

    .rdtPicker {
      margin-top: 12px;
    }
  }
}
