.item {
  i {
    opacity: 0;
  }
  &:hover {
    i {
      opacity: 1;
    }
  }
}

.ico {
  opacity: .9;
  margin-left: 8px !important;
  &:hover {
    color: #b07f18 !important;
  }
}

.btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 12px 15px !important;
  &:hover {
    .ico {
      opacity: 1;
    }
  }
}

.selectedBtn {
  @extend .btn;
  font-weight: bold !important;
  color: #b07f18 !important;
  .ico {
    opacity: 1;
  }
  .selectedIco {
    color: #b07f18 !important;
  }
}

.hiddenIco {
  opacity: 1;
}
