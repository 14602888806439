.checkbox,
.radio {
  display: flex;
  align-items: center;
  height: 32px;

  label {
    color: #61687f !important;
    font-size: 14px !important;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      border: 1px solid #8c99a5;
      width: 14px;
      height: 14px;
      vertical-align: middle;
      border-radius: 2px;
      margin-right: 10px;
      position: relative;
      top: -1px;
    }

    &.checked {
      &:before {
        content: 'g';
        font-family: eleneo;
        border: 0;
        color:  #515BB3;
        top: -5px;
        left: 0px;
      }
    }
  }

  label:not(:last-child) {
    margin-right: 14px;
  }

  input {
    display: none;
  }

  i {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    color: #b7c3c9;
    margin: 0 5px;
  }
}

.radio {
  label {
    &:before {
      content: '';
      border-radius: 50%;
    }

    &.checked {
      &:before {
        content: 'P';
        font-family: eleneo;
        border: 0;
        color:   #515BB3;
        top: -5px;
        left: 0px;
      }
    }
  }
}
