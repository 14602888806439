.uor-select {
  .dropdown {
    width: 100%;
    display: flex,
  }

  .dropdown-content {
    z-index: 10 !important;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    top: 34px;
    padding: 0 !important;
    .infinite-scroll-component {
      padding: 4px 8px !important;
    }
    .node.disabled>* {
      color: unset !important;
      cursor: unset !important;
      input {
        display: none;
      }
      .node-label {
        margin-left: 4px;
      }
    }
  }

  .dropdown-trigger {
    border-radius: 0.25rem;
    width: 100%;
    border: 1px solid #ced4da !important;
    padding: 0 4px !important;
    min-height: 32px;
  }

  .dropdown-trigger.arrow {
    cursor: default !important;
  }

  .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
  }

  .dropdown-trigger.arrow.bottom:after,
  .dropdown-trigger.arrow.top:after {
    display: none !important;
  }

  .tag-remove {
    display: none !important;
  }

  .toggle {
    font: normal bold 13px/1 eleneo;
    color: #555;
    top: 2px;
    position: relative;
  }

  .toggle.collapsed::after {
    content: "\6b";
  }

  .toggle.expanded::after {
    content: "\6a";
  }

  .search {
    border-bottom: 0;
  }

  .tag {
    border: 0;
    background-color: unset;
    color: #2e323d;
  }

  .tag-item:last-of-type {
    display: none;
  }

  .gas {
    .node-label:before {
      content: "q ";
      font-family: 'eleneo';
      color: #c53ed1;
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .elec {
    .node-label:before {
      content: "o ";
      font-family: 'eleneo';
      color: #31acea;
      font-size: 12px;
      vertical-align: middle;
    }
  }
}
