.container {
  font-size: 14px;
  color: #2e323d;
  padding: 10px 25px;

  th {
    color: #8c99a5;
    font-weight: bold;
    text-transform: uppercase;
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #f3f7f9 !important;
    }
  }
}

.energy {
  &:before {
    font-family: 'eleneo';
    margin-right: 5px;
  }

  &.gas:before {
    content: "q";
    color: #c53ed1;
  }

  &.elec:before {
    content: "o";
    color: #31acea;
    position: relative;
    top: 3px;
  }

  &.water:before {
    content: "!";
    color: #00c793;
    position: relative;
    top: 3px;
  }
}

.state {
  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    vertical-align: baseline;
    margin-right: 5px;
  }
}

.gain {
  text-align: right;
  color: #00c793;
  font-weight: bold;
}


.btnEdit {
  font-size: 14px;
  color: #8c99a5;
  border: 0;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.container {
  tr:hover {
    .btnEdit {
      opacity: 1;
    }
  }
  td {
    vertical-align: middle;
  }
}

.title,
.comment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
