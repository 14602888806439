.form-control {
  border-color: $gray-lighter;
  border-color: var(--gray-lighter, $gray-lighter);
}

.form-check-label {
  padding: 0;

  .form-inline & {
    margin-right: 30px;
  }

  input {
    display: none;
  }

  &:before {
    content: "i";
    font-family: eleneo;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    line-height: 1;
    color: $gray-light;
    color: var(--gray-light, $gray-light);
    margin-right: 0.3em;
  }

  &.disabled {
    &:before {
      content: "y";
      color: $gray-light;
      color: var(--gray-light, $gray-light);
    }
  }

  &.checked {
    &:before {
      content: "g";
      color: $blue-light;
    }

    &.disabled {
      &:before {
        color: $gray-light;
        color: var(--gray-light, $gray-light);
      }
    }
  }

  &.indeterminate {
    &:before {
      content: "h";
      color: $blue;
      color: var(--blue, $blue);
    }

    &.disabled {
      &:before {
        color: $gray-light;
        color: var(--gray-light, $gray-light);
      }
    }
  }
}

.form-inline label + select {
  margin: 0 30px 0 1em;
}

.form-graph {
  margin-bottom: 20px;
}
