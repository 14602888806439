@use "sass:math";

@keyframes glow {
  80% {
    opacity: 0.4;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(1.15, 1.75, 1);
  }
}

.sidebar-col {
  max-width: $sidebar-width;
  flex: 0 0 $sidebar-width;
  z-index: 2000;
  width: $sidebar-width;
}

.sidebar {
  background: var(--blue);
  width: 100%;
  height: 100vh;
  color: $white;
  color: var(--white, $white);
  overflow: hidden;
  position: fixed;
  width: $sidebar-width;

  ul {
    padding: 0;
    list-style: none;
  }

  .logo {
    padding: 22px 55px 28px;
    display: block;
  }

  .nav-tabs {
    font-size: $font-size-base;
    border: 0;

    .nav-item {
      &:last-child {}
    }

    .nav-link {
      background: none;
      border-radius: 0;
      border: none;
      text-align: center;
      padding: 12px 0 16px;
      color: $gray-light;
      color: var(--gray-light, $gray-light);
      cursor: pointer;

      &.active {
        color: $white;
        color: var(--white, $white);
      }

      .active {
        content: "";
        display: block;
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $blue;
      }

      .badge {
        margin-left: 5px;
        position: relative;
        top: -1px;
        font-size: 11px;
        font-weight: normal;
        height: 18px;
        text-align: center;
        line-height: 18px;
        width: auto;
        padding: 0 0.5em;
        border-radius: 10px;
      }
    }
  }

  .current-selection-tab {
    .current-selection-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 150px);

      .current-selection-header,
      .current-selection-footer {
        flex-grow: 0;
        flex-shrink: 0;
      }

      .current-selection-content {
        overflow-y: auto;

        a {
          text-decoration: none;
        }
      }

      .btn-link {
        box-shadow: none;
        outline: none;
        cursor: pointer;
      }
    }

    .toggle-panel {
      margin: 25px 0 15px;
      border-radius: 2px;
      cursor: pointer;
      position: relative;

      &.glow {
        margin-bottom: 25px;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: inherit;
          border-radius: 2px;
          z-index: -1;
          opacity: 0.4;
          transform: scale3d(1, 1, 1);
          animation: glow 4s infinite;
        }
      }
    }

    .current-selection-content {
      .current-site {
        .btn-link {
          padding: 12px 0;
          color: $white;
          color: var(--white, $white);
          font-size: $font-size-sm;
          text-decoration: none;
          outline: none;
          box-shadow: none;
          cursor: pointer;
          text-align: left;

          span {
            display: block;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          i {
            margin-left: 0.5em;
            color: $gray-light;
            color: var(--gray-light, $gray-light);
          }
        }
      }

      .current-pees {
        background: $gray-dark-lighten;
        background: var(--gray-dark-lighten, $gray-dark-lighten);
        margin-left: math.div(-$grid-gutter-width-base, 2);
        margin-right: math.div(-$grid-gutter-width-base, 2);
        padding: 6px (math.div($grid-gutter-width-base, 2));

        ul {
          margin: 0;

          li {
            padding: 6px 0;
            transition: color 0.3s ease;

            span {
              overflow: hidden;
              text-overflow: ellipsis;
            }

            i {
              transition: color 0.3s ease;
              margin-right: 0.75em;
              position: relative;
              top: -1px;

              &.icon-elec {
                color: $blue;
                color: var(--blue, $blue);
              }

              &.icon-gaz {
                color: $purple;
                color: var(--purple, $purple);
              }
            }

            .btn-group {
              margin-right: -4px;

              .btn {
                background: none;
                padding: 0 4px;
                color: $gray-light;
                color: var(--gray-light, $gray-light);
                border: none;
                opacity: 0;
                cursor: pointer;
                box-shadow: none;
                font-size: 1em;

                &:active,
                &:focus {
                  border: none;
                }

                i {
                  margin-right: 0.25em;

                  &.icon-cross {
                    font-size: 0.9em;
                  }
                }
              }
            }

            &:not(.visible) {
              color: $gray-light;
              color: var(--gray-light, $gray-light);

              i.icon-elec,
              i.icon-gaz {
                color: $gray-light;
                color: var(--gray-light, $gray-light);
              }

              .btn-group {
                .btn:first-child {
                  opacity: 1;
                }
              }
            }

            &:hover {
              .btn-group {
                .btn {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    .current-selection-footer {
      border-top: 1px solid $gray;
      border-top: 1px solid var(--gray, $gray);
      padding-top: 10px;

      .btn-link {
        padding: 10px 0;
        flex-grow: 1;
        color: $white;
        color: var(--white, $white);

        span {
          width: 100%;
          display: block;
          position: relative;
          font-size: $font-size-sm;
        }

        &:not(:last-child) {
          span:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 10%;
            bottom: 5%;
            width: 1px;
            background: $gray;
            background: var(--gray, $gray);
          }
        }
      }
    }
  }

  .favorites-tab {
    padding-top: 30px;

    .btn-link {
      padding-left: 0;
      color: $white;
      color: var(--white, $white);
      box-shadow: none;
      cursor: pointer;
      max-width: 90%;
      width: 100%;
      text-align: left;
      text-overflow: ellipsis;
    }

    div {
      .btn-secondary {
        background: none;
        padding: 0 4px;
        color: $gray-light;
        color: var(--gray-light, $gray-light);
        border: none;
        opacity: 0;
        cursor: pointer;
        box-shadow: none;

        &:active,
        &:focus {
          border: none;
        }
      }

      &:hover {
        .btn {
          opacity: 1;
        }
      }
    }
  }

  .sidebar-market {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);

    .sidebar-market-title {
      flex-grow: 0;
      flex-shrink: 0;

      h2 {
        font-size: $font-size-sm;
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray;
        border-bottom: 1px solid var(--gray, $gray);
      }
    }

    .sidebar-market-infos {
      overflow-y: auto;

      li:last-child .card {
        border: none;
      }

      .card {
        background: none;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $gray;
        border-bottom: 1px solid var(--gray, $gray);

        .card-block {
          padding: 20px 0;

          .card-subtitle {
            font-size: $font-size-xs;
            color: $gray-light;
            color: var(--gray-light, $gray-light);
            margin-bottom: 10px;
            margin-top: 0;
          }

          .card-title {
            font-size: $font-size-base;
            margin-bottom: 10px;
          }

          .card-text {
            font-size: $font-size-sm;
            font-weight: 300;
            color: $gray-lighter;
            color: var(--gray-lighter, $gray-lighter);
          }
        }

        &.empty-card {
          .card-subtitle {
            width: 70%;
            background: $gray;
            background: var(--gray, $gray);

            &:before {
              content: " ";
            }
          }

          .card-title {
            width: 90%;
            background: $gray;
            background: var(--gray, $gray);

            &:before {
              content: " ";
            }
          }

          .card-text {
            width: 100%;
            height: 100px;
            background: $gray;
            background: var(--gray, $gray);
          }
        }
      }
    }
  }
}
