@import '~leaflet/dist/leaflet.css';

.leaflet-container {
  font-family: $font-family-sans-serif !important;
  width: 100%;
  height: 100%;
}

.leaflet-popup-content-wrapper {
  border-radius: 4px;
}

.leaflet-popup-content {
  margin: 20px 28px;
  p {
    margin: 0 0 1rem 0;
  }
}

.leaflet-popup-tip-container {
  top: -20px;
}

.leaflet-popup-tip {
  position: relative;
  top: 22px;
}