.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.barContainer {
  display: flex;
  position: relative;
}

.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 8px;
}

.value {
  font-size: 14px;
  font-weight: bold;
}

.letter {
  font-size: 18px;
  font-weight: bold;
}

%arrow {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.rightArrow {
  @extend %arrow;
  border-left: 12px solid;
}

.leftArrow {
  @extend %arrow;
  border-right: 12px solid #000;
}

.cursor {
  background: #000;
  color: #fff;
  width: 90px;
}

.unit {
  position: absolute;
  right: 0;
  bottom: -25px;
  color: #61747f;
}

.hr {
  background: #ddd;
  width: 100%;
  height: 1px;
}

.legend {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: #61747f;
}