.container {
  display: flex;
  justify-content: flex-startn;
  width: 100%;
}
.nrjContainer {
  width: 330px;
  &:not(:last-child) {
    margin-right: 2rem;
  }
  h4 {
    margin-bottom: 15px;
  }
}
.item {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #61687f;
  margin-bottom: 6px;
}

.title {
  width: 130px;
  font-weight: bold;
  flex: none;
}

.gauge {
  height: 20px;
  border-radius: 12.5px;
  border-width: 1px;
  border-style: solid;
  min-width: 25px;
  line-height: 20px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-align: right;
  padding: 0 8px;
  margin-right: 6px;
}

.energy {
  &:before {
    font-family: 'eleneo';
    text-transform: lowercase;
    vertical-align: baseline;
    margin-right: 5px;
  }

  &.gas:before {
    content: "q";
    color: #c53ed1;
  }

  &.elec:before {
    content: "o";
    color: #31acea;
    position: relative;
    top: 3px;
  }

  &.water:before {
    content: "!";
    color: #00d4c3;
    position: relative;
    top: 3px;
  }
}

.value {
  width: 60px;
  white-space: nowrap;
}
