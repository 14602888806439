.grid {
  margin: -13px -13px 26px;
}

.gridItem {
  display: block;
  min-height: 300px;
  width: 258px;
  box-shadow: 2px 2px 5px 0 rgba(221, 231, 237, 0.61);
  background-color: #fff;
  margin: 13px;
  position: relative;
}