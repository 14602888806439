.lot {
  .toggle {
    cursor: pointer;
    font-size: 1.1rem;
    background: #eee;
    padding: 0.3em;
    border-top: 1px solid #ddd;
  }

  tr {
    td {
      font-size: 1rem;
    }

    td:first-child {
      font-weight: bold;
    }
  }
}
