.notifications-wrapper {
  position: relative;
  z-index: 200000;

  .notifications-bl {
    bottom: 20px !important;
    left: 20px !important;
  }

  .notification {
    background-color: $gold !important;
    box-shadow: 0 0 15px 0 rgba(#2e313d, 0.2) !important;
    border: none !important;
    padding: 1rem 1.2rem !important;
    height: auto !important;
    border-radius: 4px;

    h4 {
      margin: 0 !important;
      font-weight: semibold !important;
      color: $white !important;
    }

    .notification-message {
      color: $white !important;
      margin-top: 0.5rem !important;
    }

    &.notification-error {
      background-color: $red !important;
    }

    .notification-dismiss {
      background-color: transparent !important;
    }
  }
}
