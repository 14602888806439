.navContainer {
  margin: 0;
  overflow-x: auto;
  width: calc(100vw - 320px);
}

.nav {
  flex-wrap: nowrap;
}

.container {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 12px;
  color: #61687f;
}

.period > *:not(:last-child) {
  margin-bottom: 5px;
}

%optionIcon {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.optionIconElec {
  @extend %optionIcon;
  color: #31acea;
}

.optionIconGaz {
  @extend %optionIcon;
  color: #c53ed1;
}

.optionIconWater {
  @extend %optionIcon;
  color: #00d4c3;
}

.header {
  position: relative;
}

.pagination {
  user-select: none;
  margin-bottom: 15px;
  ul {
    justify-content: center !important;
    margin-bottom: 0;
  }
}

.btnCsv {
  float: right;
}
