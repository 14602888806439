.icon {
  background: transparent;
  border: 0;
}

%list {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 200px;
  z-index: 1000;
  //transition: opacity .3s linear .2s;
}

.openedList {
  @extend %list;
  opacity: 1;
}

.closedList {
  @extend %list;
  opacity: 0;
}
