.address {
  color: #2e323d;
}

.label {
  color: #2e323d;
  font-weight: bold;
  font-size: 14px !important;
}

.dateValue {
  margin: 0 20px 0 16px;
  min-width: 130px;
}

.datepicker {
  display: inline-block;
  input {
    width: 100px !important;
    font-size: 14px !important;
  }
}
