.pomona {
  --blue: hsl(198, 100%, 26%);
  --blue-alpha: hsl(198, 100%, 26%, .5);
  --blue-alpha3: hsl(198, 100%, 26%, .3);
  --blue-alpha7: hsl(198, 100%, 26%, .7);
  --blue-dark: hsl(198, 100%, 22%);
  --blue-light: hsl(198, 100%, 28%);

  --gold: hsl(198, 35%, 50%);
  --gold-alpha: hsl(198, 35%, 50%, .5);
  --gold-dark: hsl(198, 35%, 40%);
  --gold-light: hsl(198, 35%, 70%);

  --red: hsl(11, 76%, 64%);
  --red-alpha: hsl(11, 76%, 64%, .5);
  --red-light: hsl(11, 76%, 74%);
  --red-dark: hsl(11, 76%, 54%);

  --green: hsl(145, 42%, 52%);
  --green-alpha: hsl(145, 42%, 52%, .5);

  --gray: hsl(0, 0%, 50%);

  --primary: var(--blue);
  --secondary: var(--gold);

  --flop: var(--red);
  --flop-light: var(--red-light);

  a  {
    color: var(--blue);
  }

  .sidebar {
    .logo {
      padding: 36px 34px 24px;
      filter: grayscale(1) brightness(10);
    }

    .selectedBtn {
      color: var(--red) !important;
      .selectedIco {
        color: var(--red) !important;
      }
    }

    .sidebar-ico {
      &:hover {
        color: var(--red) !important;
      }
    }
  }

  .topNav {
    background-color: var(--secondary) !important;

    .nav-link {
      &.active {
        span {
          &:after {
            background: var(--primary);
          }
        }
      }
    }
  }

  .current-selection-content {
    .collapse {
      background-color: var(--blue-dark) !important;
    }
  }

  .spinner-border {
    color: var(--gold-light) !important;
  }

  .form-check-label.checked::before {
    color: var(--blue);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--gold);
    background-color: var(--gold);
  }

  .btn-primary {
    border-color: var(--gold);
    background-color: var(--gold);
    color: #fff;

    &:focus {
      box-shadow: inset 0 1px 0 RGB((255 255 255 / 15%)), 0 1px 1px RGB((0 0 0 / 8%)), 0 0 0 0.2rem RGB((83 145 172 / 50%));
    }

    &:hover, &:focus {
      border-color: var(--gold);
      background-color: var(--gold);
      color: #fff;
    }

    &:disabled {
      border-color: var(--gold);
      background-color: var(--gold);
      opacity: 0.5;
    }

    &:not([disabled]):not(.disabled) {
      &:active, &.active {
        border-color: var(--gold-dark);
        background-color: var(--gold-dark);
        box-shadow: none;
        color: #fff;
      }
    }
  }

  .btn-danger {
    border-color: var(--red);
    background-color: var(--red);
    color: #fff;

    &:focus {
      box-shadow: inset 0 1px 0 RGB((255 255 255 / 15%)), 0 1px 1px RGB((0 0 0 / 8%)), 0 0 0 0.2rem RGB((233 118 91 / 50%));
    }

    &:hover, &:focus {
      border-color: var(--red);
      background-color: var(--red);
      color: #fff;
    }

    &:disabled {
      border-color: var(--red);
      background-color: var(--red);
      opacity: 0.5;
    }

    &:not([disabled]):not(.disabled) {
      &:active, &.active {
        border-color: var(--red-dark);
        background-color: var(--red-dark);
        box-shadow: none;
        color: #fff;
      }
    }
  }

  // react-datepicker
  td.rdtActive,
  td.rdtActive:hover {
    background-color: var(--gold) !important;
  }

  td.rdtToday:before {
    border-bottom-color: var(--gold) !important;
  }

  // tabs
  .simple-nav-tab, .sub-nav-tab {
    .nav-link {
      &:hover::after,
      &.active::after {
        background: var(--gold) !important;
      }

      &.active {
        color: var(--gold) !important;
      }
      &:hover {
        color: var(--gold) !important;
      }
    }
  }

  // notifications
  .notifications-wrapper {
    .notification {
      background-color: var(--gold) !important;

      &.notification-error {
        background-color: var(--red) !important;
      }
    }
  }

  // alertes
  .synthesis-item-new, .state-item-new:before {
    background-color: var(--blue-alpha);
    border-color: var(--blue);
  }

  .synthesis-item-inprogress, .state-item-inprogress:before {
    background-color: var(--gold-alpha);
    border-color: var(--gold);
  }

  .synthesis-item-resolved, .state-item-resolved:before {
    background-color: var(--green-alpha);
    border-color: var(--green);
  }

  .synthesis-item-abandoned, .state-item-abandoned:before {
    background-color: var(--red-alpha);
    border-color: var(--red);
  }
}
