.list {
  background: #fff;
  width: 270px;
  box-shadow: 0px 4px 8px 0px #999;
}

.header {
  width: 100%;
  height: 36px;
  background-color: var(--blue);
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 36px;
  padding: 0 16px;
  font-weight: bold;
}

.group {
  max-height: 520px;
  overflow-y: auto;
}

.buttons {
  display: flex;
}

.link {
  color: #61687f;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link:hover {
  text-decoration: none;
}

.item {
  color: #61687f;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0 !important;
  padding: 14px 12px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(100, 100, 100, 0.1) !important;
  .text {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-right: .5rem;
  }
}

.marker {
  width: 17.5px;
  height: 25px;
  margin: 2px 16px 0 0;
  flex-shrink: 0;
}

.name {
  font-weight: 800;
  font-size: 16px;
  line-height: 1.3;
}

.address {
  font-size: 14px;
}
