.header {
  position: sticky;
  top: 76px;
  z-index: 20;
  background: #fff;
  margin-bottom: 30px;
}

.periodSelector {
  height: 100px;
}

.title {
  display: flex;
  align-items: center;

  h3 {
    margin-right: 15px;
  }
}
