.popover {
  padding-top: 16px;
  margin: 0;
  font-size: 12px;
  box-shadow: 0 0 10px 0 rgba(179, 187, 198, 0.5);
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  max-width: 369px;

  label {
    width: 100%;
    display: block;
    font-weight: bold;
  }
}

.textInput {
  font-size: 12px;
  height: 36px;

  &:focus {
    box-shadow: 0 0 0 1px rgb(0,199,147);
  }
}

.inner {
  width: 369px;
}

.datepicker {
  input {
    font-size: 12px !important;
    height: 36px !important;
  }
  button {
    height: 36px !important;
  }
}

.btnClose {
  border: none;
  color: #61687f;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 20;
  i {
    font-size: 14px;
  }
}

.btnSave {
  margin-left: .8rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

%optionIcon {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.optionIconElec {
  @extend %optionIcon;
  color: #31acea;
}

.optionIconGaz {
  @extend %optionIcon;
  color: #c53ed1;
}

.optionIconWater {
  @extend %optionIcon;
  color: #00d4c3;
}

.formLoading {
  opacity: 0.5;
  pointer-events: none;
}