.stef {
  --blue-navy: hsl(240, 94%, 35%);
  --cyan-azur: hsl(200, 100%, 41%);
  --mint-lagoon: hsl(163, 75%, 52%);
  --sea-iced: hsl(160, 92%, 79%);
  --lemon-gold: hsl(57, 100%, 47%);

  --blue: hsl(240, 94%, 35%);
  --blue-alpha: hsl(240, 94%, 35%, .5);
  --blue-alpha3: hsl(240, 94%, 35%, .3);
  --blue-alpha7: hsl(240, 94%, 35%, .7);
  --blue-dark: hsl(240, 94%, 25%);
  --blue-light: hsl(240, 94%, 45%);

  --gold: hsl(163, 75%, 52%);
  --gold-alpha: hsl(163, 75%, 52%, .5);
  --gold-dark: hsl(163, 75%, 62%);
  --gold-light: hsl(163, 75%, 42%);

  --red: hsl(200, 100%, 41%);
  --red-alpha: hsl(200, 100%, 41%, .5);
  --red-dark: hsl(200, 100%, 31%);
  --red-light: hsl(200, 100%, 51%);

  --green: hsl(160, 92%, 79%);
  --green-alpha: hsl(160, 92%, 79%, .5);

  --gray: hsl(0, 0%, 50%);


  --primary: var(--blue);
  --secondary: var(--gold);

  --flop: var(--red);
  --flop-light: var(--red-light);

  .sidebar .logo {
    padding: 30px 34px 24px;
  }

  .spinner-border {
    color: var(--gold-light) !important;
  }

  .topNav {
    background-color: var(--secondary) !important;

    .nav-link {
      &.active {
        span {
          &:after {
            background: var(--sea-iced);
          }
        }
      }
    }
  }


  
}
