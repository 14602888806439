.container {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 12px;
  color: #61687f;
}

%optionIcon {
  margin-right: 5px;
  vertical-align: text-bottom;
}

.optionIconElec {
  @extend %optionIcon;
  color: #31acea;
}

.optionIconGaz {
  @extend %optionIcon;
  color: #c53ed1;
}

.optionIconWater {
  @extend %optionIcon;
  color: #00d4c3;
}

.legend {
  display: flex;
  justify-content: center;
}

.legendItem {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
}

.header {
  display: flex;
  &>* {
    min-width: 200px;
    &:not(:first-child) {
      margin-left: 14px;
    }
  }
}

.checkbox,
.radio {
  display: flex;
  align-items: center;
  height: 26px;

  label {
    color: #61687f !important;
    font-size: 12px !important;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      border: 1px solid #8c99a5;
      width: 12px;
      height: 12px;
      vertical-align: middle;
      border-radius: 2px;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }

    &.checked {
      &:before {
        content: 'g';
        font-family: eleneo;
        border: 0;
        color:   #515BB3;
        top: -5px;
        left: 0px;
      }
    }
  }

  label:not(:last-child) {
    margin-right: 14px;
  }

  input {
    display: none;
  }
}
