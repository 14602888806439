.indicator{
  padding: 0 11px;
}

.value {
  padding: 0 13px;
  width: 150px;
}

.btn {
  i {
    color: #8c99a5;
    font-size: 13px;
    opacity: 0;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }
}

.table {
  width: auto;
  thead {
    border-bottom: 1px solid rgb(222, 226, 230);
  }
  th {
    padding-left: 20px;
  }
  tr {
    &:hover,
    &:focus,
    &.edit {
      i {
        opacity: 1;
      }
    }
  }
  td {
    height: 65px;
  }
}

.addBtn {
  height: 34px;
  margin: 0 12px;
  font-size: 14px;
}

.input {
  margin-right: .5rem;
}
