.container {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 12px;
  color: #61687f;
}

.button {
  font-size: 12px;
  color: #61687f;
  border-color: #d7dee4;
  background-color: #fff;
  &.active {
    font-weight: bold !important;
    background: #d7dee4 !important;
  }
  &:hover,
  &:focus,
  &[disabled] {
    color: #61687f !important;
    border-color: #d7dee4 !important;
  }
}

.inlineContainer {
  margin: 0;
  display: flex;
  align-items: center;
  .title {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
